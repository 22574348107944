<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Name" v-slot="context" rules="required">
        <b-form-group label="Name">
          <b-form-input
            v-model="name"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g Solna"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Coordinates" v-slot="context" rules="required">
        <b-form-group>
          <label>
            Coordinates <a :href="linkToGoogleMaps" target="_blank">Link to Google maps</a>
          </label>

          <b-form-input
            v-model="coordinates"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g 60.4720° N, 8.4689° E"
          />

          <small>
            On the Google Maps page, right click the city title and click the first element in the
            dropdown (it should say two comma-separated numbers). These are the coordinates and they are already
            copied into the clipboard - you can paste them now.
          </small>

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            Please fill in the coordinates
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group label="Top Content">
        <froala v-model="top_content" />
      </b-form-group>

      <b-form-group label="Bottom Content">
        <froala v-model="bottom_content" />
      </b-form-group>

      <b-form-group label="Listable">
        <b-form-checkbox v-model="is_listable">
          Listable
        </b-form-checkbox>
      </b-form-group>

      <hr />
      <b-row align-h="between">
        <b-col>
          <location-image-picker v-if="isEditPage" :uploadFn="handleUpload" />
        </b-col>
        <b-col cols="auto">
          <b-button variant="link" @click="$emit('close')">
            Close
          </b-button>

          <b-button variant="primary" :disabled="invalid" type="submit">
            {{ isEditPage ? 'Update' : 'Add' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { location } from '@/api'
import { getValidationState } from '../page-helpers'
import LocationImagePicker from './LocationImagePicker'
import Froala from '@/components/Froala.vue'

export default {
  name: 'CountryRegionCityForm',
  components: { LocationImagePicker, Froala },
  props: { region: { type: Object }, city: { type: Object } },
  data() {
    return { name: '', coordinates: '', top_content: '', bottom_content: '', is_listable: true }
  },
  computed: {
    isEditPage() {
      return Boolean(this.city)
    },
    linkToGoogleMaps() {
      return `https://www.google.com/maps/search/?api=1&query=${this.name.replace(' ', '+')}`
    }
  },
  mounted() {
    if (!this.isEditPage) return

    this.name = this.city.name
    this.coordinates = this.city.coordinates
    this.is_listable = this.city.is_listable
    this.top_content = this.city.top_content
    this.bottom_content = this.city.bottom_content
  },
  methods: {
    getValidationState,
    async submitFormData() {
      const data = {
        name: this.name,
        coordinates: this.coordinates,
        is_listable: this.is_listable,
        top_content: this.top_content,
        bottom_content: this.bottom_content,
      }

      if (!this.isEditPage) {
        await location.cities.create(this.region.id, data)
        this.$emit('created', data)
      } else {
        await location.cities.update(this.city.id, data)
        this.$emit('updated', data)
      }

      this.$emit('ok')
    },
    async handleUpload(image) {
      await location.cities.update(this.city.id, { image })
    },
  },
}
</script>
