<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Name" v-slot="context" rules="required">
        <b-form-group label="Name">
          <b-form-input
            v-model="name"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g Norway"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Code" v-slot="context" rules="required|alpha|length:2">
        <b-form-group label="Code">
          <b-form-input
            v-model="code"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g no"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group label="Top Content">
        <froala v-model="top_content" />
      </b-form-group>

      <b-form-group label="Bottom Content">
        <froala v-model="bottom_content" />
      </b-form-group>

      <hr />
      <b-row align-h="between">
        <b-col>
          <location-image-picker v-if="isEditPage" :uploadFn="handleUpload" />
        </b-col>
        <b-col cols="auto">
          <b-button variant="link" @click="$emit('close')">
            Close
          </b-button>

          <b-button variant="primary" :disabled="invalid" type="submit">
            {{ isEditPage ? 'Update' : 'Add' }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { location } from '@/api'
import { getValidationState } from '../page-helpers'
import LocationImagePicker from './LocationImagePicker'
import Froala from '@/components/Froala.vue'

export default {
  name: 'CountryForm',
  components: { Froala, LocationImagePicker },
  props: { country: { type: Object } },
  mounted() {
    if (!this.isEditPage) return

    this.name = this.country.name
    this.code = this.country.code
    this.top_content = this.country.top_content
    this.bottom_content = this.country.bottom_content
  },
  data() {
    return { name: '', code: '', top_content: '', bottom_content: '' }
  },
  computed: {
    isEditPage() {
      return Boolean(this.country)
    },
  },
  methods: {
    getValidationState,
    async submitFormData() {
      const data = {
        name: this.name,
        code: this.code.toUpperCase(),
        top_content: this.top_content,
        bottom_content: this.bottom_content,
      }

      if (!this.isEditPage) {
        await location.countries.create(data)
        this.$emit('created', data)
      } else {
        await location.countries.update(this.country.id, data)
        this.$emit('updated', data)
      }

      this.$emit('ok')
    },
    async handleUpload(image) {
      await location.countries.update(this.country.id, { image })
    },
  },
}
</script>
