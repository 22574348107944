<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row align-v="center">
      <b-col cols="auto">
        <h1 class="page-header">Countries</h1>
      </b-col>

      <b-col cols="auto" class="ml-auto">
        <b-button variant="primary" v-b-modal.newCountry :disabled="$cannot('places.manage')">
          Add Country
        </b-button>

        <b-modal id="newCountry" title="Add Country" v-slot:default="{ close }" hide-footer>
          <country-form @close="close" @ok="handleOk(close)" @created="countryCreated" />
        </b-modal>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col v-for="(country, i) in countries" :key="i" :cols="6" class="mb-4">
        <b-row align-v="center" class="mb-2">
          <b-col cols="auto">
            <img :src="generateFlag(country)" />
          </b-col>

          <b-col>
            <h3>{{ country.name }}</h3>
          </b-col>

          <b-col cols="auto">
            <b-dropdown right text="Options" variant="primary" size="sm" :disabled="$cannot('places.manage')">
              <b-dropdown-item v-b-modal="`addRegion-${country.slug}`">
                Add Region
              </b-dropdown-item>
              <b-dropdown-item v-b-modal="`editCountry-${country.slug}`">
                Edit <strong>{{ country.name }}</strong>
              </b-dropdown-item>
            </b-dropdown>

            <b-modal :id="`addRegion-${country.slug}`" hide-footer>
              <template v-slot:modal-title>
                <b-row align-v="end">
                  <b-col cols="auto">
                    <img :src="generateFlag(country)" />
                  </b-col>

                  <b-col> {{ country.name }}: Add Region </b-col>
                </b-row>
              </template>

              <template v-slot:default="{ close }">
                <country-region-form :country="country" @close="close" @ok="handleOk(close)" @created="regionCreated" />
              </template>
            </b-modal>

            <b-modal :id="`editCountry-${country.slug}`" hide-footer>
              <template v-slot:modal-title>
                <b-row align-v="end">
                  <b-col cols="auto">
                    <img :src="generateFlag(country)" />
                  </b-col>

                  <b-col>Edit {{ country.name }}</b-col>
                </b-row>
              </template>

              <template v-slot:default="{ close }">
                <country-form :country="country" @close="close" @ok="handleOk(close)" @updated="countryUpdated" />
              </template>
            </b-modal>
          </b-col>
        </b-row>

        <b-list-group>
          <b-list-group-item v-for="(region, j) in country.regions" :key="j">
            <b-row>
              <b-col>
                <strong>{{ region.name }}</strong>
              </b-col>

              <b-col cols="auto">
                <b-dropdown right text="More" variant="default" size="sm" :disabled="$cannot('places.manage')">
                  <b-dropdown-item v-b-modal="`addCity-${region.slug}`">
                    Add City
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal="`editRegion-${region.slug}`">
                    Edit <strong>{{ region.name }}</strong>
                  </b-dropdown-item>
                </b-dropdown>

                <b-modal
                  :title="`${region.name}: Add City`"
                  :id="`addCity-${region.slug}`"
                  v-slot:default="{ close }"
                  hide-footer
                >
                  <country-region-city-form
                    :region="region"
                    @close="close"
                    @ok="handleOk(close)"
                    @created="cityCreated"
                  />
                </b-modal>

                <b-modal :title="`${region.name}: Edit Region`" :id="`editRegion-${region.slug}`" hide-footer>
                  <template v-slot:default="{ close }">
                    <country-region-form
                      :region="region"
                      @close="close"
                      @ok="handleOk(close)"
                      @updated="regionUpdated"
                    />
                  </template>
                </b-modal>
              </b-col>
            </b-row>

            <hr />

            <span v-for="(city, i) in region.cities" :key="i" class="mr-2">
              <b-button
                variant="default"
                v-b-modal="`editCity-${city.slug}`"
                size="sm"
                :disabled="$cannot('places.manage')"
              >
                {{ city.name }}
              </b-button>

              <b-modal
                :title="`${city.name}: Edit City`"
                :id="`editCity-${city.slug}`"
                v-slot:default="{ close }"
                hide-footer
              >
                <country-region-city-form :city="city" @close="close" @ok="handleOk(close)" @updated="cityUpdated" />
              </b-modal>
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { location } from '@/api'
import CountryForm from './CountryForm.vue'
import CountryRegionForm from './CountryRegionForm.vue'
import CountryRegionCityForm from './CountryRegionCityForm.vue'

export default {
  name: 'CountriesPage',
  inject: ['$cannot'],
  components: { CountryForm, CountryRegionForm, CountryRegionCityForm },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  data() {
    return {
      countries: [],
    }
  },
  methods: {
    async loadContent() {
      const response = await location.countries.all()

      this.countries = response.data()
    },
    generateFlag(country) {
      return `https://www.countryflags.io/${country.code}/shiny/24.png`
    },
    handleOk(callback) {
      this.loadContent()
      callback()
    },
    countryCreated(country) {
      this.$bvToast.toast(`"${country.name}" was created successfully.`, {
        title: 'Country created.',
        variant: 'primary',
      })
    },
    countryUpdated(country) {
      this.$bvToast.toast(`"${country.name}" was updated successfully.`, {
        title: 'Country updated.',
        variant: 'primary',
      })
    },
    regionCreated(region) {
      this.$bvToast.toast(`"${region.name}" was created successfully.`, {
        title: 'Region created.',
        variant: 'primary',
      })
    },
    regionUpdated(region) {
      this.$bvToast.toast(`"${region.name}" was updated successfully.`, {
        title: 'Region updated.',
        variant: 'primary',
      })
    },
    cityCreated(city) {
      this.$bvToast.toast(`"${city.name}" was created successfully.`, {
        title: 'City created.',
        variant: 'primary',
      })
    },
    cityUpdated(city) {
      this.$bvToast.toast(`"${city.name}" was updated successfully.`, {
        title: 'City updated.',
        variant: 'primary',
      })
    },
  },
}
</script>
