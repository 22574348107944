<template>
  <div>
    <b-button variant="outline-primary" v-b-modal="`image-modal`">
      Upload Image
    </b-button>

    <b-modal ref="modal" id="image-modal" title="Upload Image" hide-footer>
      <b-overlay :show="loading" rounded="sm">
        <b-form-file @input="propagate" accept="image/*" />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LocationImagePicker',
  props: { uploadFn: { type: Function } },
  data() {
    return { loading: false }
  },
  methods: {
    propagate(file) {
      this.loading = true
      this.uploadFn(file)
      this.$refs.modal.hide()
      this.loading = false
      this.$bvToast.toast('Image has been successfully uploaded', { title: 'ImageUploaded', variant: 'primary' })
    },
  },
}
</script>
